import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { handleError } from '../../ErrorHandler/errorHandlerPropsMap';
import { Challenges } from '../../../editor/types/Experiments';
import { OP_APP_DEF_ID } from '../../../appDefId';
import { PARTICIPANT_PAGE_ID } from '../../../editor/app-config';
import {
  Challenge,
  ChallengeSummary,
  MemberChallenge,
} from '@wix/ambassador-challenges-v1-challenge/types';
import { State } from '@wix/ambassador-challenges-v1-participant/types';
import { getParticipantStateFromSummary } from '../../../components/ChallengesList/Badge/helpers/GetParticipantStateFromSummary';
import { locationProviderPropsMap } from '../locationProviderPropsMap';

export const isUserGoesToParticipantPage = (summary: ChallengeSummary) => {
  return [
    State.COMPLETED,
    State.FAILED,
    State.JOINED,
    State.RUNNING,
    State.SUSPENDED,
  ].includes(getParticipantStateFromSummary(summary));
};
export const isParticipantPageAvailable = async (
  flowAPI: ControllerFlowAPI,
) => {
  if (flowAPI.experiments.enabled(Challenges.redirectToParticipantPage)) {
    return flowAPI.controllerConfig.wixCodeApi.site.isAppSectionInstalled({
      appDefinitionId: OP_APP_DEF_ID,
      sectionId: PARTICIPANT_PAGE_ID,
    });
  }
  return false;
};

async function getProgramPageUrl({
  flowAPI,
  isParticipant,
}: {
  flowAPI: ControllerFlowAPI;
  isParticipant?: boolean;
}) {
  try {
    if (isParticipant && (await isParticipantPageAvailable(flowAPI))) {
      return await flowAPI.controllerConfig.wixCodeApi.site.getSectionUrl({
        sectionId: PARTICIPANT_PAGE_ID,
        appDefinitionId: flowAPI.controllerConfig.appParams.appDefinitionId,
      });
    }
    return await flowAPI.controllerConfig.wixCodeApi.site.getSectionUrl({
      sectionId: 'challenge_page',
      appDefinitionId: flowAPI.controllerConfig.appParams.appDefinitionId,
    });
  } catch (error) {
    handleError({
      error,
      context: 'getSectionUrl',
    });
  }
  return;
}
const getProgramSlug = (program: Challenge) => {
  return program?.settings?.seo?.slug;
};
export const generateLinksToProgramForList = async (
  flowAPI: ControllerFlowAPI,
  membersPrograms: MemberChallenge[] = [],
) => {
  const linksToProgram: {
    [id: string]: string;
  } = {};

  await Promise.all(
    membersPrograms.map(async (memberProgram) => {
      const urlInfo = await getProgramPageUrl({
        flowAPI,
        isParticipant: isUserGoesToParticipantPage(memberProgram.summary),
      });
      linksToProgram[memberProgram.challenge.id] = `${
        urlInfo.url
      }/${getProgramSlug(memberProgram.challenge)}`;
    }),
  );

  return linksToProgram;
};

export const navigateToProgramPage = async (
  flowAPI: ControllerFlowAPI,
  options: { challengeId: string; isParticipant: boolean },
) => {
  locationProviderPropsMap(flowAPI).goToPage({
    challengeId: options.challengeId,
    pageId:
      (await isParticipantPageAvailable(flowAPI)) && options.isParticipant
        ? PARTICIPANT_PAGE_ID
        : 'challenge_page',
  });
};
